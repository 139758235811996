<template>
  <v-main>
    <AppBarPortal/>
    <v-container class="pt-14 pb-5" fluid>
      <v-row class="aen_lr_padding">
        <div class="mainContent">
          <div class="container-fluid customPadding pt-5 pb-5">
            <div class="row aen_lr_padding">

              <div class="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 customPaddingRight">
                <h1>Ακαδημίες</h1>
                <p>Η εκπαίδευση των στελεχών του εμπορικού ναυτικού στην Ελλάδα, διεξάγεται σε γεωγραφικά κατανεμημένες
                  έντεκα Ακαδημίες Εμπορικού Ναυτικού ειδικοτήτων Πλοιάρχων και Μηχανικών, ως ακολούθως:</p>

                <div class="card">
                  <h5>ΑΕΝ ΑΣΠΡΟΠΥΡΓΟΥ</h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Μηχανικών</span>
                    </h6>
                    <p class="card-text">
                      Παραλία Ασπροπύργου, Ασπρόπυργος Αττικής - Τ.Κ. 193 00<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνα Επικοινωνίας : </a>210 5574171 - 2 - 3, 210 5575985<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a href="mailto:aenasprop@hcg.gr">aenasprop@hcg.gr</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5><a href="https://sites.google.com/site/aenmak/" target="_blank">ΑΕΝ ΜΑΚΕΔΟΝΙΑΣ</a></h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Μηχανικών</span>
                    </h6>
                    <p class="card-text">
                      Νέα Μηχανιώνα Θεσσαλονίκης - Τ.Κ. 570 04<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνα Επικοινωνίας : </a>23920 31322, 23920 31280<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a href="mailto:aenmakedonias@hcg.gr">aenmakedonias@hcg.gr</a><br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Ιστοσελίδα : </a><a href="https://sites.google.com/site/aenmak/"
                                                             target="_blank">https://sites.google.com/site/aenmak/</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5><a href="http://aen-kritis.chan.sch.gr/" target="_blank">ΑΕΝ ΚΡΗΤΗΣ</a></h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Μηχανικών</span>
                    </h6>
                    <p class="card-text">
                      Βλητές Σούδας, Χανιά Κρήτης - Τ.Κ. 732 00<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>28210 89356<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a href="mailto:aenkritis@hcg.gr">aenkritis@hcg.gr</a><br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Ιστοσελίδα : </a><a href="http://aen-kritis.chan.sch.gr/" target="_blank">http://aen-kritis.chan.sch.gr/</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5><a href="https://www.aenhydra.gr/" target="_blank">ΑΕΝ ΥΔΡΑΣ</a></h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Ύδρα - Τ.Κ. 180 40<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>22980 52208<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a href="mailto:aenydra@hcg.gr">aenydra@hcg.gr</a><br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Ιστοσελίδα : </a><a href="https://www.aenhydra.gr/" target="_blank">https://www.aenhydra.gr/</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5><a href="https://aenkimis.weebly.com/" target="_blank">ΑΕΝ ΚΥΜΗΣ</a></h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Παραλία Κύμης, Κύμη - Τ.Κ. 340 03<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνα Επικοινωνίας : </a>22220 22602, 2222022167<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a
                        href="mailto:aenkimis@hcg.gr">aenkimis@hcg.gr</a><br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Ιστοσελίδα : </a><a href="https://aenkimis.weebly.com/" target="_blank">https://aenkimis.weebly.com/</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5><a href="http://aensyrou.edu.gr" target="_blank">ΑΕΝ ΣΥΡΟΥ</a></h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Παπαδάμ 1, Ερμούπολη Σύρου - Τ.Κ. 841 00<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>22810 82450<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a
                        href="mailto:aensyrou@hcg.gr ">aensyrou@hcg.gr</a><br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Ιστοσελίδα : </a><a href="http://aensyrou.edu.gr" target="_blank">http://aensyrou.edu.gr</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5><a href="https://www.aenchiou.gr" target="_blank">ΑΕΝ ΧΙΟΥ</a></h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Μηχανικών</span>
                    </h6>
                    <p class="card-text">
                      Δημοκρατίας 26, Χίος - Τ.Κ. 821 00<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>22710 44431<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a
                        href="mailto:aenchiou@hcg.gr">aenchiou@hcg.gr</a><br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Ιστοσελίδα : </a><a href="https://www.aenchiou.gr" target="_blank">https://www.aenchiou.gr</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5>ΑΕΝ ΟΙΝΟΥΣΣΩΝ</h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Οινούσσες Χίου - Τ.Κ. 821 01<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>22710 55209<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a href="mailto:aenoinousson@hcg.gr">aenoinousson@hcg.gr</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5>ΑΕΝ ΙΟΝΙΩΝ ΝΗΣΩΝ</h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Πλατεία Μέιτλαντ, Αργοστόλι Κεφαλονιάς - Τ.Κ. 281 00<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>26710 28608<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a
                        href="mailto:aenionia@hcg.gr">aenionia@hcg.gr</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5>ΑΕΝ ΗΠΕΙΡΟΥ</h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Βαθύ Πρέβεζας - Τ.Κ. 481 00 <br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνα Επικοινωνίας : </a>26820 22095 - 7, 26820 89511 -12<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a href="mailto:aenipi@hcg.gr">aenipi@hcg.gr</a><br>
                    </p>
                  </div>
                </div>

                <div class="card">
                  <h5>ΑΕΝ ΚΑΛΥΜΝΟΥ</h5>
                  <div class="card-body">
                    <h6 class="card-title">
                      <span class="pb-1">Σχολή Πλοιάρχων</span>
                    </h6>
                    <p class="card-text">
                      Ενορία Καλαμιώτισσας, Κάλυμνος - Τ.Κ. 852 00<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Τηλέφωνο Επικοινωνίας : </a>22430 - 59505<br>
                      <a href="#" class="text-black" style="pointer-events: none;cursor: default;text-decoration: none;"
                         target="_blank">Email Επικοινωνίας : </a><a
                        href="mailto:aenkalymnou@hcg.gr">aenkalymnou@hcg.gr</a><br>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4  col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <v-img
                    class="img-fluid rounded sticky-top mt-1"
                    lazy-src="@/assets/images/aenmap.jpg"
                    src="@/assets/images/aenmap.jpg"
                    alt="Σύστημα Ηλεκτρονικής Διαχείρισης Σπουδών Ακαδημιών Εμπορικού Ναυτικού (ΑΕΝ)"/>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
    <AppFooterPortal/>
  </v-main>
</template>

<script>
import AppBarPortal from "@/components/AppBarPortal.vue";
import AppFooterPortal from "@/components/AppFooterPortal.vue";

export default {
  components: {AppFooterPortal, AppBarPortal},
  data() {
    return {}
  },

  methods: {},
};
</script>

<style>
.aen_lr_padding {
  padding-left: 6rem;
  padding-right: 6rem;
}

.mainContent {
  padding-bottom: 100px;
}

.mainContent p {
  font-size: 0.95rem;
}

@media (min-width: 1400px) {
  .customPaddingRight {
    padding-right: 120px;
  }
}
</style>